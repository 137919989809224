import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Map, fromJS } from 'immutable';

import BrowseAutoComplete from './BrowseAutoComplete';
import BrowseCheckbox from './BrowseCheckbox';
import Tag from './Tag';

class BrowseAutoCompleteCountry extends PureComponent {
  constructor(props) {
    super(props);

    this.handleRemoveTag = this.handleRemoveTag.bind(this);
    this.handleAddTag = this.handleAddTag.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.focusOut = false;
  }

  componentDidUpdate() {
    if (this.focusOut) {
      this.button.focus();
      this.focusOut = false;
    }
  }

  handleCheckboxChange() {
    const checked = this.props.infoCountry.get('checked');

    ['country', 'continent'].forEach(filterName => {
      this.props.onChange({
        filterName,
        filterData: {
          checked: !checked,
        },
      });
    });
  }

  handleChange(filterName, filterData, info) {
    if (filterData.selected) {
      let selectedList = info.get('selectedList');
      const selected = fromJS(filterData.selected);
      const field = selected.has('id') ? 'id' : 'value';

      if (selectedList.findIndex(s => s.get(field) === selected.get(field)) === -1) {
        selectedList = selectedList.push(selected.set('include', true));
        filterData.selectedList = selectedList;
      }
      this.focusOut = true;
    }
    this.props.onChange({
      filterName,
      filterData,
    });
  }

  handleRemoveTag(event, info, filter) {
    event.stopPropagation();

    const { name } = event.target;
    const tagIndex = parseInt(name.replace('tag', ''), 10);
    let selectedList = info.get('selectedList');

    selectedList = selectedList.delete(tagIndex);

    this.props.onChange({
      filterName: filter.get('name'),
      filterData: {
        selectedList,
      },
    });
  }

  handleChangeStatus(index, info, filter) {
    const { onChange } = this.props;
    const selectedList = info.get('selectedList');

    onChange({
      filterName: filter.get('name'),
      filterData: {
        selectedList: selectedList.setIn([index, 'exclude'], !selectedList.getIn([index, 'exclude'])),
      },
    });
  }

  handleAddTag(info, filter) {
    const { onChange } = this.props;

    if (!filter.get('enableDynamicAdd', false) || info.get('text', '') === '') return false;

    let selectedList = info.get('selectedList');
    const selected = fromJS({
      value: info.get('text'),
      name: info.get('text'),
    });

    if (selectedList.findIndex(s => s.get('value') === selected.get('value')) === -1) {
      selectedList = selectedList.push(selected);

      onChange({
        filterName: filter.get('name'),
        filterData: {
          text: '',
          selectedList,
        },
      });
    }
  }

  render() {
    const { filterCountry, filterContinent, infoContinent, infoCountry } = this.props;

    const tagListContentCountry = infoCountry
      .get('selectedList')
      .filter(tag => tag)
      .map((tag, index) => (
        <Tag
          key={index}
          exclude={tag.get('exclude')}
          index={index}
          onChangeStatus={index => this.handleChangeStatus(index, infoCountry, filterCountry)}
          onRemoveTag={event => this.handleRemoveTag(event, infoCountry, filterCountry)}
          value={tag.get(filterCountry.get('renderField'))}
        />
      ));
    const autocompleteCountry = (
      <>
        {tagListContentCountry}
        <div className="form-inline">
          <BrowseAutoComplete
            filter={filterCountry}
            info={infoCountry}
            onChange={({ filterName, filterData }) => this.handleChange(filterName, filterData, infoCountry)}
            onGetNextSuggestion={this.props.onGetNextCountrySuggestion}
            onGetSuggestion={this.props.onGetCountrySuggestion}
            highlightFirstSuggestion
          >
            <span
              ref={input => {
                this.button = input;
              }}
              className="input-group-addon"
              onClick={() => this.handleAddTag(infoCountry, filterCountry)}
              tabIndex="-1"
            >
              <i aria-hidden="true" className="fa fa-plus" />
            </span>
          </BrowseAutoComplete>
        </div>
      </>
    );

    const tagListContentContinent = infoContinent
      .get('selectedList')
      .filter(tag => tag)
      .map((tag, index) => (
        <Tag
          key={index}
          exclude={tag.get('exclude')}
          index={index}
          onChangeStatus={index => this.handleChangeStatus(index, infoContinent, filterContinent)}
          onRemoveTag={event => this.handleRemoveTag(event, infoContinent, filterContinent)}
          value={tag.get(filterContinent.get('renderField'))}
        />
      ));
    const autocompleteContinent = (
      <>
        {tagListContentContinent}
        <div className="form-inline">
          <BrowseAutoComplete
            filter={filterContinent}
            info={infoContinent}
            onChange={({ filterName, filterData }) => this.handleChange(filterName, filterData, infoContinent)}
            onGetNextSuggestion={this.props.onGetNextContinentSuggestion}
            onGetSuggestion={this.props.onGetContinentSuggestion}
            highlightFirstSuggestion
          >
            <span
              ref={input => {
                this.button = input;
              }}
              className="input-group-addon"
              onClick={() => this.handleAddTag(infoContinent, filterContinent)}
              tabIndex="-1"
            >
              <i aria-hidden="true" className="fa fa-plus" />
            </span>
          </BrowseAutoComplete>
        </div>
      </>
    );

    return (
      <BrowseCheckbox filter={filterCountry} info={infoCountry} onChange={this.handleCheckboxChange}>
        <div className="BrowseControl-content BrowseControl-content--add">
          <div className="mb10">{autocompleteCountry}</div>
          {autocompleteContinent}
        </div>
      </BrowseCheckbox>
    );
  }
}
BrowseAutoCompleteCountry.propTypes = {
  filterContinent: PropTypes.instanceOf(Map).isRequired,
  filterCountry: PropTypes.instanceOf(Map).isRequired,
  infoContinent: PropTypes.instanceOf(Map).isRequired,
  infoCountry: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  onGetContinentSuggestion: PropTypes.func.isRequired,
  onGetCountrySuggestion: PropTypes.func.isRequired,
  onGetNextContinentSuggestion: PropTypes.func.isRequired,
  onGetNextCountrySuggestion: PropTypes.func.isRequired,
};

export default BrowseAutoCompleteCountry;
